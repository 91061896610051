<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
          >
            Sell Your Tickets
          </p>
        </v-card>
      </v-card>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'pa-0' : 'px-8 py-71'">
        <v-card flat color="lightGrey" width="863" class="border-r-0 ma-auto">
          <v-form v-if="!success" class="px-10 py-8" ref="form">
            <p>
              If you would like to sell your tickets on Football Tickets Live,
              please leave your details below.
            </p>
            <p
              class="mb-6 font-18 line-h-28 font-weight--bold black--text letter-s-25"
            >
              Your Details
            </p>
            <v-row dense class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="First Name*"
                  :rules="rules.first_name"
                  v-model="form.first_name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Last Name*"
                  :rules="rules.last_name"
                  v-model="form.last_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <v-text-field
                  hide-details="auto"
                  autocomplete="off"
                  type="email"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Email Address*"
                  :rules="rules.email"
                  v-model="form.email"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                      >mdi-email-outline</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="6" class="e-col">
                <div class="d-flex flex-column w-100">
                  <v-text-field
                    hide-details="auto"
                    autocomplete="off"
                    flat
                    solo
                    outlined
                    class="form-input border-r-0"
                    placeholder="Phone Number*"
                    :rules="rules.phone"
                    v-model="form.phone"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="#D3D5D7" class="mr-3 ml-5 mt-1"
                        >mdil-phone</v-icon
                      >
                    </template>
                  </v-text-field>
                  <span
                    class="font-13 line-h-20 font-weight--regular mute--text"
                    >Please include your country code</span
                  >
                </div>
              </v-col>
              <v-col cols="12" md="6" class="e-col">
                <div class="d-flex flex-column w-100">
                  <v-text-field
                    hide-details="auto"
                    autocomplete="off"
                    flat
                    solo
                    outlined
                    class="form-input border-r-0"
                    placeholder="Mobile Number*"
                    :rules="rules.mobile"
                    v-model="form.mobile"
                  >
                    <template v-slot:prepend-inner>
                      <img
                        color="#D3D5D7"
                        class="mr-3 ml-5 mt-1"
                        src="@/assets/images/smartphone.png"
                        alt=""
                      />
                    </template>
                  </v-text-field>
                  <span
                    class="font-13 line-h-20 font-weight--regular mute--text"
                    >Please include your country code</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <v-textarea
                  hide-details="auto"
                  autocomplete="off"
                  type="email"
                  flat
                  solo
                  outlined
                  class="form-input border-r-0"
                  placeholder="Details of tickets available for resale"
                  :rules="rules.details"
                  v-model="form.details"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <div class="d-flex justify-end mt-6">
              <v-btn
                :ripple="false"
                color="transparent"
                class="sell-your-ticket-submit height-59 width-166 btn-primary text-capitalize font-20 line-h-20 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
                elevation="0"
                @click="submit"
                :loading="loading"
              >
                Submit
                <img
                  class="ml-2 mt-1"
                  src="@/assets/images/right-arrow.svg"
                  alt=""
                />
              </v-btn>
            </div>
          </v-form>
          <div v-if="success" class="text-center px-10 py-8">
            <p
              :class="$vuetify.breakpoint.xsOnly ? 'px-5' : ''"
              class="py-20 mb-6 bg-color-grey-200 font-18 line-h-25 font-weight--bold text-color-secondary-100 letter-s-25 mb-0 d-flex align-center justify-center"
            >
              Thank you for reaching us. One of our team members will get back
              to you shortly.
            </p>
            <v-btn
              :ripple="false"
              color="transparent"
              class="sell-your-ticket-submit height-59 width-166 btn-primary text-capitalize font-18 line-h-18 font-weight--bold letter-s-0 text-color-secondary-100 border-r-0"
              elevation="0"
              to="/"
            >
              Buy Tickets
              <img class="ml-2 mt-1" src="@/assets/images/stadium.svg" alt="" />
            </v-btn>
          </div>
        </v-card>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";

export default {
  name: "SellYourTickets",
  components: { TheHeader, TheFooter },
  data: () => ({
    success: false,
    loading: false,
    form: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      mobile: null,
      details: null,
    },
    rules: {
      email: [
        (v) => !!v || "Please enter email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "Please enter a valid email address e.g. example@example.com",
      ],
      first_name: [(v) => !!v || "Please enter first name"],
      last_name: [(v) => !!v || "Please enter last name"],
      phone: [(v) => !!v || "Please enter phone number"],
      mobile: [(v) => !!v || "Please enter mobile number"],
      details: [(v) => !!v || "Please fill in details"],
    },
  }),
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        await this.$store
          .dispatch("static/SELL_YOUR_TICKET", this.form)
          .then((res) => {
            if (res.result === "success") {
              this.$refs.form.reset();
              this.success = true;
              this.loading = false;
            } else {
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            err.type = "error";
            this.$store.dispatch("snackbar/SHOW", err);
          });
      }
    },
  },
};
</script>
<style></style>
